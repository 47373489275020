"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSocketEvent = exports.Importance = exports.SortTaskType = exports.ITaskStatus = exports.AppColor = void 0;
var AppColor;
(function (AppColor) {
    AppColor["grey"] = "grey";
    AppColor["blue"] = "blue";
    AppColor["red"] = "red";
    AppColor["green"] = "green";
    AppColor["dark"] = "dark";
})(AppColor = exports.AppColor || (exports.AppColor = {}));
var ITaskStatus;
(function (ITaskStatus) {
    ITaskStatus["inComplete"] = "inComplete";
    ITaskStatus["complete"] = "complete";
})(ITaskStatus = exports.ITaskStatus || (exports.ITaskStatus = {}));
var SortTaskType;
(function (SortTaskType) {
    SortTaskType["createdAt"] = "Data utworzenia";
    SortTaskType["title"] = "Alfabetycznie";
    SortTaskType["importance"] = "Wa\u017Cno\u015B\u0107";
})(SortTaskType = exports.SortTaskType || (exports.SortTaskType = {}));
var Importance;
(function (Importance) {
    Importance["normal"] = "Normal";
    Importance["high"] = "High";
})(Importance = exports.Importance || (exports.Importance = {}));
var WebSocketEvent;
(function (WebSocketEvent) {
    WebSocketEvent["addTask"] = "add-task";
    WebSocketEvent["removeTask"] = "remove-task";
    WebSocketEvent["editTask"] = "edit-task";
    WebSocketEvent["taskStatusChange"] = "task-status-change";
})(WebSocketEvent = exports.WebSocketEvent || (exports.WebSocketEvent = {}));
